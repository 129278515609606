import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import React, { useMemo } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./components/shared/Router/ProtectedRoute";
import PublicRoute from "./components/shared/Router/PublicRoute";
import routes from "./routes";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const App = () => {
	const theme = useMemo(() => {
		return createTheme({
			typography: {
				fontFamily: ["Jost"].join(","),
			},
			palette: {
				primary: {
					main: "#E24717",
				},
			},
		});
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<ToastContainer />
			<CssBaseline />
			<BrowserRouter>
				<Switch>
					{routes.map((item, i) =>
						item.meta.requiresAuth ? (
							<ProtectedRoute
								key={i}
								path={item.path}
								exact={true}
								component={item.component}
							/>
						) : (
							<PublicRoute
								key={i}
								path={item.path}
								exact={true}
								component={item.component}
							/>
						)
					)}
				</Switch>
			</BrowserRouter>
		</ThemeProvider>
	);
};

export default App;
