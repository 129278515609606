import React from "react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { LandingUrls } from "../../../../constants/siteUrl";
import { useStyles } from "./styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
	AboutDropdownMenu,
	DAppDropdownMenu,
	MarketDropdownMenu,
	MoreDropdownMenu,
} from "./dropDownMenu";

const NavList = () => {
	const classes = useStyles();

	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	return (
		<>
			<List className={classes.list}>
				{/* Home menu */}
				<NavLink to={LandingUrls.home}>
					<ListItem>
						<ListItemText onClick={scrollTop}>Home</ListItemText>
					</ListItem>
				</NavLink>

				{/* About Menu */}
				<ListItem className={classes.dropdown}>
					<ListItemText sx={{ display: "flex", alignItems: "center" }}>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<span>About</span>
							<KeyboardArrowDownIcon />
						</Box>
					</ListItemText>
					{/* dropdown */}
					<AboutDropdownMenu />
				</ListItem>

				{/* MLOKY Menu */}
				<NavLink to="/mloky/home">
					<ListItem sx={{ cursor: "pointer" }}>
						<ListItemText>Mloky</ListItemText>
					</ListItem>
				</NavLink>

				{/* Marketplace menu */}
				<ListItem className={classes.dropdown}>
					<ListItemText sx={{ display: "flex", alignItems: "center" }}>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<span>Marketplace</span>
							<KeyboardArrowDownIcon />
						</Box>
					</ListItemText>
					{/* dropdown */}
					<MarketDropdownMenu />
				</ListItem>

				{/* DApp  menu  */}
				<ListItem className={classes.dropdown}>
					<ListItemText sx={{ display: "flex", alignItems: "center" }}>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<span>DApp </span>
							<KeyboardArrowDownIcon />
						</Box>
					</ListItemText>
					{/* dropdown */}
					<DAppDropdownMenu />
				</ListItem>

				{/* Roadmap menu */}
				<ListItem sx={{ cursor: "pointer" }}>
					<HashLink smooth to="/#roadmap">
						<ListItemText>Roadmap</ListItemText>
					</HashLink>
				</ListItem>

				{/* More menu */}
				<ListItem className={classes.dropdown}>
					<ListItemText sx={{ display: "flex", alignItems: "center" }}>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<span>More</span>
							<KeyboardArrowDownIcon />
						</Box>
					</ListItemText>
					{/* dropdown */}
					<MoreDropdownMenu />
				</ListItem>
			</List>
		</>
	);
};

export default NavList;
