import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import { HashLink } from "react-router-hash-link";
import { LandingUrls } from "../../../../constants/siteUrl";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useStyles } from "./styled";
import { NavLink } from "react-router-dom";

export const CompanyAccordion = () => {
	const classes = useStyles();

	return (
		<Accordion elevation={0} className={classes.accordion}>
			<AccordionSummary
				expandIcon={<AddIcon />}
				aria-controls="panel2a-content"
				id="panel2a-header"
			>
				<Typography className={classes.listTitle}>Company</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<List className={classes.list}>
					<NavLink to={LandingUrls.about} className={classes.navLink}>
						<ListItem>
							<ListItemText>About Us</ListItemText>
						</ListItem>
					</NavLink>
					<ListItem sx={{ cursor: "pointer" }}>
						<HashLink smooth to="/#roadmap">
							<ListItemText>Roadmap</ListItemText>
						</HashLink>
					</ListItem>
					<ListItem sx={{ cursor: "pointer" }}>
						<HashLink to="/#faqs" smooth>
							<ListItemText>FAQ</ListItemText>
						</HashLink>
					</ListItem>
				</List>
			</AccordionDetails>
		</Accordion>
	);
};

export const ServicesAccordion = () => {
	const classes = useStyles();
	return (
		<Accordion elevation={0} className={classes.accordion}>
			<AccordionSummary
				expandIcon={<AddIcon />}
				aria-controls="panel2a-content"
				id="panel2a-header"
			>
				<Typography className={classes.listTitle}>Service</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<List className={classes.list}>
					<NavLink to={LandingUrls.howToBuy} className={classes.navLink}>
						<ListItem>
							<ListItemText>Buy Crypto</ListItemText>
						</ListItem>
					</NavLink>
					<ListItem>
						<a
							href="https://lunachownft.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<ListItemText>Buy NFT</ListItemText>
						</a>
					</ListItem>
					<NavLink to={LandingUrls.charity} className={classes.navLink}>
						<ListItem>
							<ListItemText>Donate</ListItemText>
						</ListItem>
					</NavLink>
				</List>
			</AccordionDetails>
		</Accordion>
	);
};

export const LegalAccordion = () => {
	const classes = useStyles();
	return (
		<Accordion elevation={0} className={classes.accordion}>
			<AccordionSummary
				expandIcon={<AddIcon />}
				aria-controls="panel2a-content"
				id="panel2a-header"
			>
				<Typography className={classes.listTitle}>Legal</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<List className={classes.list}>
					<NavLink to={LandingUrls.privacy}>
						<ListItem>
							<ListItemText>Privacy Policy</ListItemText>
						</ListItem>
					</NavLink>
					<NavLink to={LandingUrls.disclaim}>
						<ListItem>
							<ListItemText>Disclaimer</ListItemText>
						</ListItem>
					</NavLink>
					<NavLink to={LandingUrls.terms} className={classes.navLink}>
						<ListItem>
							<ListItemText>Terms & Conditions</ListItemText>
						</ListItem>
					</NavLink>
				</List>
			</AccordionDetails>
		</Accordion>
	);
};
