import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { LandingUrls } from "../../../../constants/siteUrl";
import { useStyles } from "./styled.js";
import { List, ListItem, ListItemText, Paper } from "@mui/material";

// about dropdown menu
export const AboutDropdownMenu = (props) => {
	return (
		<Paper elevation={10}>
			<List>
				<Link to={LandingUrls.about}>
					<ListItem>
						<ListItemText>About Us</ListItemText>
					</ListItem>
				</Link>
				<Link to={LandingUrls.howToBuy}>
					<ListItem>
						<ListItemText>How To buy</ListItemText>
					</ListItem>
				</Link>
			</List>
		</Paper>
	);
};

// market dropdown menu
export const MarketDropdownMenu = (props) => {
	const classes = useStyles();
	return (
		<Paper elevation={10}>
			<List>
				<a
					href="https://lunachownft.com/"
					target="_blank"
					rel="noopener noreferrer"
					className={classes.linkText}
				>
					<ListItem>
						<ListItemText>NFT</ListItemText>
					</ListItem>
				</a>
			</List>
		</Paper>
	);
};

// DApp  dropdown menu
export const DAppDropdownMenu = (props) => {
	const classes = useStyles();
	return (
		<Paper elevation={10}>
			<List>
				<a
					href="https://dashboard.luchowswap.com/dashboard"
					target="_blank"
					rel="noopener noreferrer"
					className={classes.linkText}
				>
					<ListItem>
						<ListItemText>Dashboard</ListItemText>
					</ListItem>
				</a>
				<a
					href="https://bsc.luchowswap.com/swap"
					target="_blank"
					rel="noopener noreferrer"
					className={classes.linkText}
				>
					<ListItem>
						<ListItemText>LuchowSwap </ListItemText>
					</ListItem>
				</a>
			</List>
		</Paper>
	);
};

// more dropdown menu
export const MoreDropdownMenu = (props) => {
	return (
		<Paper elevation={10}>
			<List>
				{/* Charity */}
				<NavLink to={LandingUrls.charity}>
					<ListItem>
						<ListItemText>Charity</ListItemText>
					</ListItem>
				</NavLink>
				<HashLink to="/#faqs" smooth>
					<ListItem>
						<ListItemText>FAQ</ListItemText>
					</ListItem>
				</HashLink>
				<HashLink to="/#contact" smooth>
					<ListItem>
						<ListItemText>Contact Us</ListItemText>
					</ListItem>
				</HashLink>
			</List>
		</Paper>
	);
};
