import React from "react";
import Footer from "../components/Landing/shared/Footer";
// import Header from "../components/Landing/shared/Header";
import ResponsiveNavbar from "../components/Landing/shared/Header/ResponsiveNavbar";

const LandingLayout = ({ children }) => {
	return (
		<>
			{/* <Header /> */}
			<ResponsiveNavbar />
			{children}
			<Footer />
		</>
	);
};

export default LandingLayout;
