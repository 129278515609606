import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		backgroundColor: "#FFE2D9",
	},
	avatar: {
		width: "100% !important",
		height: "auto !important",
		borderRadius: "0 !important",

		[theme.breakpoints.down("lg")]: {
			margin: "2rem 0 !important",
		},
	},
	logoAvatar: {
		width: "100% !important",
		maxWidth: 173.47,
		height: "auto !important",
		borderRadius: "0 !important",
		marginBottom: 10,
	},
	desc: {
		fontSize: "18px !important",
		color: "#989898",
	},

	listTitle: {
		fontSize: "20px !important",
		color: "#000",
	},
	list: {
		"& .MuiListItem-root": {
			padding: 0,
		},
		"& a": {
			textDecoration: "none !important",

			"& .MuiTypography-root": {
				color: "#989898",
			},
		},
	},

	navLink: {
		display: "inline-block",
	},

	divider: {
		borderColor: theme.palette.primary.main + "!important",
	},
	socialLink: {
		gap: "10px",
		justifyContent: "flex-end !important",
		[theme.breakpoints.down("lg")]: {
			gap: "5px",
			margin: "1rem 0",
			justifyContent: "center !important",
		},
	},
	iconButton: {
		backgroundColor: theme.palette.primary.main + "!important",
		color: "#FFE2D9 !important",
	},

	accordion: {
		margin: ".5rem 0",
		border: "0 !important",
		padding: "0",
		backgroundColor: "#FFE2D9 !important",
		"& .MuiAccordionDetails-root": {
			paddingTop: "0",
			paddingBottom: "0",
			"& .MuiList-root": {
				padding: "0 !important",
			},
		},
		"& .MuiAccordionSummary-content": {
			margin: "0 !important",
		},
		"& .MuiButtonBase-root": {
			minHeight: "30px !important",
			padding: "0 !important",
			backgroundColor: "none !important",
		},
	},
}));
