import LogoImg from "../assets/images/logo.png";
import CertikLogoImg from "../assets/images/certik-logo.png";

// hero
import avalancheBtnImg from "../assets/images/home/button-img/avalanche.png";
import exchangeBtnImg from "../assets/images/home/button-img/exchange.png";
import ethereumiBtnImg from "../assets/images/home/button-img/ethereumimg.png";
import chainBtnImg from "../assets/images/home/button-img/chain.png";
import himgBtnImg from "../assets/images/home/button-img/himg.png";
import polygonBtnImg from "../assets/images/home/button-img/polygon.png";

// Currency
import Currency1Img from "../assets/images/currency/currency1.png";
import Currency2Img from "../assets/images/currency/currency2.png";
import Currency3Img from "../assets/images/currency/currency3.png";
import Currency4Img from "../assets/images/currency/currency4.png";
import Currency5Img from "../assets/images/currency/currency5.png";
import Currency6Img from "../assets/images/currency/currency6.png";
import Currency7Img from "../assets/images/currency/currency7.png";
import Currency8Img from "../assets/images/currency/currency8.png";
import Currency9Img from "../assets/images/currency/currency9.png";

// Donations
import DonationImg from "../assets/images/donations/donation.png";
import Donation1Img from "../assets/images/donations/donation1.png";
import Donation2Img from "../assets/images/donations/donation2.png";
import Donation3Img from "../assets/images/donations/donation3.png";
import Optimal2Img from "../assets/images/donations/optimal-children-home/optimal2.png";
import Optimal3Img from "../assets/images/donations/optimal-children-home/optimal3.png";
import Hogc1Img from "../assets/images/donations/hearts-of-gold-childrens-hospice/hogc1.png";
import Hogc2Img from "../assets/images/donations/hearts-of-gold-childrens-hospice/hogc2.png";
import Hogc3Img from "../assets/images/donations/hearts-of-gold-childrens-hospice/hogc3.png";
import Ashw1Img from "../assets/images/donations/AshaJyothi-andicapped-welfare-society/ashw1.png";
import Ashw2Img from "../assets/images/donations/AshaJyothi-andicapped-welfare-society/ashw2.png";
import Ashw3Img from "../assets/images/donations/AshaJyothi-andicapped-welfare-society/ashw3.png";

// Package
import Package1Img from "../assets/images/package/package1.png";
import Package2Img from "../assets/images/package/package2.png";
import Package3Img from "../assets/images/package/package3.png";
import Package4Img from "../assets/images/package/package4.png";
import Package5Img from "../assets/images/package/package5.png";
import Package6Img from "../assets/images/package/package6.png";

import HomeHeroImg from "../assets/images/home/hero-img.png";
import HomeGifImg from "../assets/images/heroImg/Hero-spin.gif";
import HomeMainHeroImg from "../assets/images/home/__hero-img-main.png";
import FairLaunch1Img from "../assets/images/fairLaunch1.png";

import FooterCoinImg from "../assets/images/footerCoin.png";
import MapImg from "../assets/images/map.png";
import RocketImg from "../assets/images/rocket.png";
import UserImg from "../assets/images/user.png";
import ChartImg from "../assets/images/chart.png";
import WorldGlobeImg from "../assets/images/worldGlobe.png";

// Partner
import PartnerEtherImg from "../assets/images/partner/logo-ether.png";
import AboutHeroImg from "../assets/images/about/hero.png";
import AboutCommunityImg from "../assets/images/about/about-community.png";
import DisclaimImg from "../assets/images/disclaim.png";
import Privacy1Img from "../assets/images/privacy1.png";
import Privacy2Img from "../assets/images/privacy2.png";
import TermsImg from "../assets/images/terms.png";
import CheckedImg from "../assets/images/checked.png";
import NonCheckedImg from "../assets/images/nonChecked.png";
import PoocoinCharts from "../assets/images/partner/lgo-poocoin-charts.png";
import CoingeckoImg from "../assets/images/partner/coingecko.png";
import CoinMarketcapImg from "../assets/images/partner/coinMarketcap.png";
import CoinsbitImg from "../assets/images/partner/Coinsbit.png";
import CointigerImg from "../assets/images/partner/cointiger.png";
import DextoolsImg from "../assets/images/partner/dextools.png";
import LatokenImg from "../assets/images/partner/Latoken.png";
import QuickSwapImg from "../assets/images/partner/QuickSwap.png";
import SolscanImg from "../assets/images/partner/Solscan.png";

import BscIMg from "../assets/images/partner/logo-bsc.png";
import NomicsImg from "../assets/images/partner/logo-nomics.png";

import PancakeSwap from "../assets/images/partner/logo-pancake.png";
import UniswapImg from "../assets/images/partner/uniswap.png";
import VisaImg from "../assets/images/partner/visa.png";
import VisaImgMloky from "../assets/images/partner/visa_mloky.png";

// whitepaper
import WhitePaperHeroImg from "../assets/images/whitepaper/whitepaper-hero-img.png";
import IndustryImg from "../assets/images/whitepaper/industry.png";
import LunachowDifferenceImg from "../assets/images/whitepaper/lunachow-difference.png";
import HowToBuyHeroImg from "../assets/images/how-to-buy-hero-img.png";

// Roadmap
import CurrencyImg from "../assets/images/roadmap/currency.png";
import TwiceImg from "../assets/images/roadmap/twice.png";
import CardImg from "../assets/images/roadmap/card.png";
import MikeImg from "../assets/images/roadmap/mike.png";

// users
import CuteGirlImg from "../assets/images/users/cute-girl.png";
import SemiUserImg from "../assets/images/users/semi.png";

// card top img
import AvalancheImg from "../assets/images/card-top-icon/avalanche.png";
import ChainImg from "../assets/images/card-top-icon/chain.png";
import CirculatingImg from "../assets/images/card-top-icon/circulating.png";
import MlokyImg from "../assets/images/card-top-icon/mloky.png";
import NftImg from "../assets/images/card-top-icon/nft.png";
import SmartChainImg from "../assets/images/card-top-icon/smartchain.png";
import TotalCurrencyImg from "../assets/images/card-top-icon/totalcurrency.png";
import Solana from "../assets/images/card-top-icon/Solana.png";
import Harmony from "../assets/images/card-top-icon/Harmony.png";
import Fantom from "../assets/images/card-top-icon/Fantom.png";
import AlarmclockImg from "../assets/images/card-top-icon/Alarmclock.png";
import DexImg from "../assets/images/card-top-icon/dex.png";
import EarnImg from "../assets/images/card-top-icon/Earn-Icon.png";
import TradeImg from "../assets/images/card-top-icon/Trade-Icon.png";

// core Values
import TransparencyImg from "../assets/images/core/transparency.png";
import CommitmentImg from "../assets/images/core/commitment.png";
import CollaborationImg from "../assets/images/core/collaboration.png";
import CommunityImg from "../assets/images/core/Community.png";

// Featured
import LondonImg from "../assets/images/Featured/london.png";
import NasdaqImg from "../assets/images/Featured/nasdaq.png";
import YahooFinanceImg from "../assets/images/Featured/YahooFinanceLogo.png";
import YahooNewsImg from "../assets/images/Featured/YahooNews.png";
import BezingaImg from "../assets/images/Featured/Bezinga.png";
import Marketwatch from "../assets/images/Featured/marketwatch.png";

// swap
import DogImg from "../assets/images/swap/dogImg.png";
import EthereumImg from "../assets/images/swap/ethereumImg.png";
import SwapImg from "../assets/images/swap/SwapImg.png";

// mloky
import MLOKYImg from "../assets/images/mloky/MLOKY.png";
import MainLogoImg from "../assets/images/mloky/mainLogo.svg";
// import MlokyLogoImg from "../assets/images/mloky/logoImg.svg";
import HeroImg from "../assets/images/mloky/hero-img.png";
import AboutImg from "../assets/images/mloky/about-img.png";

// card top images
import MarketingImg from "../assets/images/mloky/card-top-img/Marketing.png";
import AutoLiquidityImg from "../assets/images/mloky/card-top-img/AutoLiquidity.png";
import ReflectionsImg from "../assets/images/mloky/card-top-img/Reflections.png";
import AntiMWhaleMechanismImg from "../assets/images/mloky/card-top-img/Anti-Whale-Mechanism.png";
import CharityForKidsImg from "../assets/images/mloky/card-top-img/Charity-for-Kids.png";
import DeflationaryImg from "../assets/images/mloky/card-top-img/Deflationary.png";
import LiquidityImg from "../assets/images/mloky/card-top-img/Liquidity.png";
import PresaleImg from "../assets/images/mloky/card-top-img/Presale.png";
import Marketing2Img from "../assets/images/mloky/card-top-img/Marketing2.png";

// showup
import ShowUpOneImg from "../assets/images/showup/showup1.jpg";
import ShowUpTwoImg from "../assets/images/showup/showup2.jpg";
import ShowUpThreeImg from "../assets/images/showup/showup3.jpg";

// mloky about icon
import NomicsIcon from "../assets/images/mloky/about/nomics.png";
import CoinCapIcon from "../assets/images/mloky/about/coincap.png";
import CoingeckoIcon from "../assets/images/mloky/about/coingecko.png";
import CoinTigerIcon from "../assets/images/mloky/about/cointiger.png";

const Images = {
	CertikLogoImg,
	DexImg,
	EarnImg,
	TradeImg,

	// about mloky icon
	NomicsIcon,
	CoinCapIcon,
	CoingeckoIcon,
	CoinTigerIcon,

	HomeGifImg,
	// showup
	ShowUpOneImg,
	ShowUpTwoImg,
	ShowUpThreeImg,

	// mloky
	MLOKYImg,
	MainLogoImg,
	// LogoImg,
	HeroImg,
	AboutImg,

	MarketingImg,
	ReflectionsImg,
	AutoLiquidityImg,
	AntiMWhaleMechanismImg,
	CharityForKidsImg,
	DeflationaryImg,
	LiquidityImg,
	PresaleImg,
	Marketing2Img,

	// hero - home
	avalancheBtnImg,
	exchangeBtnImg,
	ethereumiBtnImg,
	chainBtnImg,
	himgBtnImg,
	polygonBtnImg,

	LogoImg,

	// Currency
	Currency1Img,
	Currency2Img,
	Currency3Img,
	Currency4Img,
	Currency5Img,
	Currency6Img,
	Currency7Img,
	Currency8Img,
	Currency9Img,

	// Donation,
	DonationImg,
	Donation1Img,
	Donation2Img,
	Donation3Img,

	Optimal2Img,
	Optimal3Img,
	Hogc1Img,
	Hogc2Img,
	Hogc3Img,
	Ashw1Img,
	Ashw2Img,
	Ashw3Img,

	// Package
	Package1Img,
	Package2Img,
	Package3Img,
	Package4Img,
	Package5Img,
	Package6Img,

	FooterCoinImg,

	HomeMainHeroImg,
	HomeHeroImg,
	FairLaunch1Img,
	MapImg,

	HowToBuyHeroImg,

	PartnerEtherImg,
	RocketImg,
	UserImg,
	ChartImg,

	AboutHeroImg,
	AboutCommunityImg,

	WorldGlobeImg,
	DisclaimImg,
	Privacy1Img,
	Privacy2Img,
	TermsImg,
	CheckedImg,
	NonCheckedImg,

	// whitepaper
	WhitePaperHeroImg,
	IndustryImg,
	LunachowDifferenceImg,

	// partner
	PancakeSwap,
	UniswapImg,

	BscIMg,
	NomicsImg,

	CoingeckoImg,
	CoinMarketcapImg,
	CoinsbitImg,
	CointigerImg,
	DextoolsImg,
	LatokenImg,
	QuickSwapImg,
	SolscanImg,
	PoocoinCharts,
	VisaImg,
	VisaImgMloky,

	// roadmap
	CurrencyImg,
	TwiceImg,
	CardImg,
	MikeImg,

	// users
	CuteGirlImg,
	SemiUserImg,

	// card-top-img
	AvalancheImg,
	ChainImg,
	CirculatingImg,
	MlokyImg,
	NftImg,
	SmartChainImg,
	TotalCurrencyImg,
	Harmony,
	Solana,
	Fantom,
	AlarmclockImg,

	// core values
	TransparencyImg,
	CommitmentImg,
	CollaborationImg,
	CommunityImg,

	// Featured
	LondonImg,
	NasdaqImg,
	YahooFinanceImg,
	YahooNewsImg,
	BezingaImg,
	Marketwatch,

	// swap
	EthereumImg,
	DogImg,
	SwapImg,
};

export { Images };
