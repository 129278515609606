import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	dropdown: {
		position: "relative !important",

		"& .MuiTypography-root": {
			color: "#E95C27 !important",
			fontSize: "20px !important",
			fontWeight: "500 !important",
		},

		"& .MuiPaper-root": {
			position: "absolute",
			minWidth: "195px !important",
			maxWidth: "195px !important",
			borderTop: "4px solid #E8561F",
			borderRadius: "0",
			top: "3rem",
			left: "0",
			// extra
			opacity: 1,
			transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
			transformOrigin: "0px 0px",
			display: "none",

			[theme.breakpoints.down("md")]: {
				zIndex: "5",
			},

			"& .MuiList-root": {
				padding: "0 !important",

				"& .MuiListItem-root": {
					minHeight: "auto !important",

					"&:hover": {
						backgroundColor: "#FFD8CC",
					},
					"& .MuiListItemText-root": {},
				},
			},
		},

		"&:hover": {
			"& .MuiPaper-root": {
				display: "block",
			},
		},
	},

	appBar: {
		backgroundColor: "#FFD8CC !important",
		"& .MuiToolbar-root": {
			minHeight: "90px !important",
		},
	},
	logo: {
		width: "100% !important",
		height: "100% !important",
		maxWidth: 244,
		borderRadius: "0 !important",
	},
	menuWrap: {
		"& .MuiPaper-root": {
			width: "100% !important",
		},
	},

	list: {
		display: "flex",
		justifyContent: "flex-end",

		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
		},

		"& a": {
			textDecoration: "none !important",

			"& .MuiTypography-root": {
				color: "#E95C27 !important",
				fontSize: "20px !important",
				fontWeight: "500 !important",
			},
		},
	},
	// listItemText: {
	// 	display: "flex !important",
	// },

	menu: {
		"& .MuiPaper-root": {
			minWidth: "195px !important",
			borderTop: "4px solid #E8561F",
			borderRadius: "0",
		},
		"& .MuiList-root": {
			"& a": {
				textDecoration: "none !important",
				color: "#4C4C4C !important",
			},
		},
		"& .MuiMenuItem-root": {
			fontSize: "20px !important",
		},
		"& .MuiMenuItem-root:hover": {
			backgroundColor: "#FFD8CC",
		},
	},
	linkText: {
		textDecoration: "none !important",
	},
}));
