import ProgressBar from "react-topbar-progress-indicator";
import LazyLoading from "../components/shared/Router/LazyLoading";
import { LandingUrls } from "../constants/siteUrl";
import LandingLayout from "../layouts/LandingLayout";
import MlokyLayout from "../layouts/MlokyLayout";

const suspenseOption = {
	fallback: <ProgressBar />,
	layoutComponent: LandingLayout,
};

const mlokySuspenseOption = {
	fallback: <ProgressBar />,
	layoutComponent: MlokyLayout,
};

const landingRoutes = [
	{
		path: LandingUrls.home,
		layout: LandingLayout,
		meta: { requiresAuth: false },
		component: LazyLoading(
			() => import("../pages/Landing/Home"),
			suspenseOption
		),
	},
	{
		path: LandingUrls.about,
		layout: LandingLayout,
		meta: { requiresAuth: false },
		component: LazyLoading(
			() => import("../pages/Landing/About"),
			suspenseOption
		),
	},
	{
		path: LandingUrls.charity,
		layout: LandingLayout,
		meta: { requiresAuth: false },
		component: LazyLoading(
			() => import("../pages/Landing/Charity"),
			suspenseOption
		),
	},
	{
		path: LandingUrls.disclaim,
		layout: LandingLayout,
		meta: { requiresAuth: false },
		component: LazyLoading(
			() => import("../pages/Landing/Disclaim"),
			suspenseOption
		),
	},
	{
		path: LandingUrls.privacy,
		layout: LandingLayout,
		meta: { requiresAuth: false },
		component: LazyLoading(
			() => import("../pages/Landing/Privacy"),
			suspenseOption
		),
	},
	{
		path: LandingUrls.terms,
		layout: LandingLayout,
		meta: { requiresAuth: false },
		component: LazyLoading(
			() => import("../pages/Landing/Terms"),
			suspenseOption
		),
	},
	{
		path: LandingUrls.swap,
		layout: LandingLayout,
		meta: { requiresAuth: false },
		component: LazyLoading(
			() => import("../pages/Landing/Swap"),
			suspenseOption
		),
	},
	{
		path: LandingUrls.whitepaper,
		layout: LandingLayout,
		meta: { requiresAuth: false },
		component: LazyLoading(
			() => import("../pages/Landing/Whitepaper"),
			suspenseOption
		),
	},
	{
		path: LandingUrls.howToBuy,
		layout: LandingLayout,
		meta: { requiresAuth: false },
		component: LazyLoading(
			() => import("../pages/Landing/HowToBuy"),
			suspenseOption
		),
	},
	{
		path: LandingUrls.mloky,
		layout: MlokyLayout,
		meta: { requiresAuth: false },
		component: LazyLoading(
			() => import("../pages/Landing/Mloky"),
			mlokySuspenseOption
		),
	},
];

export default landingRoutes;
