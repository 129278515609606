export const LandingUrls = {
	home: "/",
	about: "/about",
	charity: "/charity",
	disclaim: "/disclaim",
	privacy: "/privacy",
	terms: "/terms",
	swap: "/swap",
	whitepaper: "/whitepaper",
	howToBuy: "/how-to-buy",
	mloky: "/mloky/home",
};
