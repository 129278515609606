import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import NavList from "./NavList";
import { Images } from "../../../../constants/themeData";
import { useStyles } from "./styled";
import { Link } from "react-router-dom";
import { LandingUrls } from "../../../../constants/siteUrl";

const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const ResponsiveNavbar = () => {
	const classes = useStyles();

	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	return (
		<AppBar position="sticky" elevation={0} className={classes.appBar}>
			<Container maxWidth="lg">
				<Toolbar disableGutters>
					{/* logo in large device */}
					<Box
						noWrap
						// sx={{ mr: 2, flexGrow: 1, display: { xs: "none", md: "flex" } }}
						sx={{ mr: 2, flexGrow: 1, display: "flex" }}
					>
						<Link to={LandingUrls.home}>
							<Avatar src={Images.LogoImg} className={classes.logo} />
						</Link>
					</Box>

					{/* logo  in small device */}
					{/* <Box noWrap sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}> */}
					{/* <Box noWrap sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
						<Avatar src={Images.LogoImg} className={classes.logo} />
					</Box> */}

					{/* menu list open after click bar in small device */}
					{/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}> */}
					<Box sx={{ display: { xs: "flex", md: "none" } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="primary"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							className={classes.menuWrap}
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
							}}
						>
							{/* {pages.map((page) => (
								<MenuItem key={page} onClick={handleCloseNavMenu}>
									<Typography textAlign="center">{page}</Typography>
								</MenuItem>
							))} */}
							<NavList />
						</Menu>
					</Box>

					{/* logo  in small device */}
					{/* <Box noWrap sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}> */}
					{/* <Box noWrap sx={{ display: { xs: "flex", md: "none" } }}>
						<Avatar src={Images.LogoImg} className={classes.logo} />
					</Box> */}

					{/* menu list in large device */}
					<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
						{/* {pages.map((page) => (
							<Button
								key={page}
								onClick={handleCloseNavMenu}
								sx={{ my: 2, color: "white", display: "block" }}
							>
								{page}
							</Button>
						))} */}
						<NavList />
					</Box>

					{/*  Profile section in large & small*/}
					{/* <Box sx={{ flexGrow: 0 }}>
						<Tooltip title="Open settings">
							<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
								<Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
							</IconButton>
						</Tooltip>
						<Menu
							sx={{ mt: "45px" }}
							id="menu-appbar"
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>
							{settings.map((setting) => (
								<MenuItem key={setting} onClick={handleCloseNavMenu}>
									<Typography textAlign="center">{setting}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box> */}
				</Toolbar>
			</Container>
		</AppBar>
	);
};

export default ResponsiveNavbar;
