import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { scrollingTop } from "../../../utils/helpers";

const ProtectedRoute = ({
    component,
    isAuthenticate,
    currentUser,
    ...rest
}) => {
    useEffect(() => {
        scrollingTop();
    }, []);

    const routeComponent = (props) => {
        return React.createElement(component, props);
    };

    return <Route {...rest} render={routeComponent} />;
};
export default ProtectedRoute;
