import {
	Facebook,
	GitHub,
	Instagram,
	Reddit,
	Telegram,
	Twitter,
} from "@mui/icons-material";
import {
	Avatar,
	Container,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Stack,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { LandingUrls } from "../../../../constants/siteUrl";
import { Images } from "../../../../constants/themeData";
import { useStyles } from "./styled";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { CompanyAccordion, LegalAccordion, ServicesAccordion } from "./Menus";

const Footer = () => {
	const classes = useStyles();

	const theme = useMemo(() => {
		return createTheme({
			typography: {
				fontFamily: ["Roboto"].join(","),
			},
			palette: {
				primary: {
					main: "#E24717",
				},
			},
		});
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Box py={5} className={classes.wrapper}>
				<Container maxWidth="lg">
					<Box>
						<Grid container spacing={5} alignItems="center">
							<Grid item xs={12} lg={5}>
								<Grid container spacing={2} alignItems="center">
									<Grid item xs={3}>
										<Avatar
											src={Images.FooterCoinImg}
											className={classes.avatar}
										/>
									</Grid>
									<Grid item xs={12} lg={9}>
										<Avatar
											src={Images.LogoImg}
											className={classes.logoAvatar}
										/>
										<Typography className={classes.desc}>
											LUCHOW is a decentralized digital currency that can be
											used as a medium of exchange or to facilitate a trade. It
											is a borderless democratic form of digital token
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} lg={7}>
								<Grid container spacing={1}>
									<Grid item xs={12} lg={4}>
										<Box sx={{ display: { xs: "block", lg: "none" } }}>
											<CompanyAccordion />
										</Box>
										<Box sx={{ display: { xs: "none", lg: "block" } }}>
											<Typography className={classes.listTitle}>
												Company
											</Typography>
											<List className={classes.list}>
												<NavLink
													to={LandingUrls.about}
													className={classes.navLink}
												>
													<ListItem>
														<ListItemText>About Us</ListItemText>
													</ListItem>
												</NavLink>
												{/* <NavLink to={LandingUrls.home}> */}
												<ListItem sx={{ cursor: "pointer" }}>
													<HashLink smooth to="/#roadmap">
														<ListItemText>Roadmap</ListItemText>
													</HashLink>
												</ListItem>
												{/* </NavLink> */}
												{/* <NavLink to="/"> */}
												<ListItem sx={{ cursor: "pointer" }}>
													<HashLink to="/#faqs" smooth>
														<ListItemText>FAQ</ListItemText>
													</HashLink>
												</ListItem>
												{/* </NavLink> */}
											</List>
										</Box>
									</Grid>
									<Grid item xs={12} lg={4}>
										<Box sx={{ display: { xs: "block", lg: "none" } }}>
											<ServicesAccordion />
										</Box>
										<Box sx={{ display: { xs: "none", lg: "block" } }}>
											<Typography className={classes.listTitle}>
												Service
											</Typography>
											<List className={classes.list}>
												{/* <NavLink to={LandingUrls.about}>
												<ListItem>
													<ListItemText>About Us</ListItemText>
												</ListItem>
											</NavLink> */}
												{/* <NavLink to={LandingUrls.terms}>
												<ListItem>
													<ListItemText>Terms & Conditions</ListItemText>
												</ListItem>
											</NavLink> */}
												<NavLink
													to={LandingUrls.howToBuy}
													className={classes.navLink}
												>
													<ListItem>
														<ListItemText>Buy Crypto</ListItemText>
													</ListItem>
												</NavLink>
												<ListItem>
													<a
														href="https://lunachownft.com/"
														target="_blank"
														rel="noopener noreferrer"
													>
														<ListItemText>Buy NFT</ListItemText>
													</a>
												</ListItem>
												<NavLink
													to={LandingUrls.charity}
													className={classes.navLink}
												>
													<ListItem>
														<ListItemText>Donate</ListItemText>
													</ListItem>
												</NavLink>
												{/* <NavLink to="/">
												<ListItem>
													<ListItemText>Privacy Policy</ListItemText>
												</ListItem>
											</NavLink> */}
											</List>
										</Box>
									</Grid>
									<Grid item xs={12} lg={4}>
										<Box sx={{ display: { xs: "block", lg: "none" } }}>
											<LegalAccordion />
										</Box>
										<Box sx={{ display: { xs: "none", lg: "block" } }}>
											<Typography className={classes.listTitle}>
												Legal
											</Typography>
											<List className={classes.list}>
												<NavLink to={LandingUrls.privacy}>
													<ListItem>
														<ListItemText>Privacy Policy</ListItemText>
													</ListItem>
												</NavLink>
												<NavLink to={LandingUrls.disclaim}>
													<ListItem>
														<ListItemText>Disclaimer</ListItemText>
													</ListItem>
												</NavLink>
												<NavLink
													to={LandingUrls.terms}
													className={classes.navLink}
												>
													<ListItem>
														<ListItemText>Terms & Conditions</ListItemText>
													</ListItem>
												</NavLink>
											</List>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
					<Box py={2}>
						<Divider className={classes.divider} />
					</Box>
					<Box py={2}>
						<Grid container alignItems="center">
							<Grid item xs={12} lg={6}>
								<Typography textAlign="center" variant="body1" color="primary">
									Copyright © {new Date().getFullYear()}, LunaChow LLC | All
									Rights Reserved
								</Typography>
							</Grid>
							<Grid item xs={12} lg={6}>
								<Stack className={classes.socialLink} direction="row">
									<a
										href="https://www.facebook.com/LunaChowOfficial"
										target="_blank"
										rel="noopener noreferrer"
									>
										<div>
											<IconButton className={classes.iconButton}>
												<Facebook />
											</IconButton>
										</div>
									</a>

									<a
										href="https://t.me/LuChow"
										target="_blank"
										rel="noopener noreferrer"
									>
										<div>
											<IconButton className={classes.iconButton}>
												<Telegram />
											</IconButton>
										</div>
									</a>

									<a
										href="https://www.instagram.com/lunachow.io/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<div>
											<IconButton className={classes.iconButton}>
												<Instagram />
											</IconButton>
										</div>
									</a>

									<a
										href="https://github.com/lunachoww/contract"
										target="_blank"
										rel="noopener noreferrer"
									>
										<div>
											<IconButton className={classes.iconButton}>
												<GitHub />
											</IconButton>
										</div>
									</a>

									<a
										href="https://www.reddit.com/r/LunaChow"
										target="_blank"
										rel="noopener noreferrer"
									>
										<div>
											<IconButton className={classes.iconButton}>
												<Reddit />
											</IconButton>
										</div>
									</a>

									<a
										href="https://twitter.com/LuchowSwap"
										target="_blank"
										rel="noopener noreferrer"
									>
										<div>
											<IconButton className={classes.iconButton}>
												<Twitter />
											</IconButton>
										</div>
									</a>
								</Stack>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
		</ThemeProvider>
	);
};

export default Footer;
